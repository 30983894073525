<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer">
              <b-card>
                <b-card-body class="p-0">
                  <b-card-title> Manage your events. </b-card-title>
                  <div class="guide-tip">
                    <b-row align-v="center">
                      <b-col cols="3" lg="1" class="icon">
                        <font-awesome-icon :icon="['far', 'calendar-alt']" />
                      </b-col>
                      <b-col
                        cols="12"
                        lg="8"
                        order="3"
                        order-lg="2"
                        class="text"
                      >
                        Need to make changes to an event you have already
                        created, cancel an event, or create a new event? Use the
                        action dropdown below.
                      </b-col>
                      <b-col
                        cols="9"
                        lg="3"
                        order="2"
                        order-lg="3"
                        class="button"
                      >
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          v-b-modal.modal-theme-guide
                        >
                          View Example
                          <font-awesome-icon
                            :icon="['far', 'window-maximize']"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <b-button
                    variant="primary"
                    class="mb-4"
                    @click="$router.push(`add-event`)"
                  >
                    Add Event
                  </b-button>
                  <b-alert :show="!!errorMessage" variant="danger">{{
                    errorMessage
                  }}</b-alert>

                  <b-table
                    striped
                    responsive
                    ref="table"
                    :fields="fields"
                    :items="eventItems"
                  >
                    <template #cell(eventDetail)="data">
                      <div class="event-detail">
                        <ul>
                          <li>
                            <label class="font-weight-bold">Title:</label>
                            <div>
                              {{ data.item.title }}
                            </div>
                          </li>
                          <li v-show="!!data.item.description">
                            <label class="font-weight-bold">Description:</label>
                            <div>
                              {{ data.item.description }}
                            </div>
                          </li>
                          <li v-show="!!data.item.ctaLink">
                            <div>
                              <a
                                target="_blank"
                                :href="`${data.item.ctaLink}`"
                                >{{ data.item.ctaText }}</a
                              >
                            </div>
                          </li>
                          <li v-show="!!data.item.coverPhotoUrl">
                            <div>
                              <a
                                target="_blank"
                                :href="`${data.item.coverPhotoUrl}`"
                                >View Cover Photo</a
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template #cell(action)="data">
                      <b-form-select
                        v-model="data.item.action"
                        @change="onOptionChange($event, data.item)"
                        :options="actionOptions"
                      ></b-form-select>
                    </template>
                  </b-table>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onFormSubmit"
                @on-back="onBack"
                @on-preview="onPreview"
                @on-skip="onSkip"
                :hide-save-button="eventItems.length == 0"
                :saving="saving"
                :invalid="false"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <!-- <candidate-media-preview
      :showMediaPreview="showMediaPreview"
      :media="media"
      @hide="
        showMediaPreview = false;
        media = {};
      "
    ></candidate-media-preview> -->
    <ModalThemeGuide
      theme-id="events"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import CandidateMixin from "@/mixins/candidate.mixin";
export default {
  name: "ManageEvents",
  mixins: [CandidateMixin],
  data() {
    return {
      loading: false,
      errorMessage: null,
      fields: [
        {
          label: "Date/Time",
          key: "dateTimeText",
        },
        {
          label: "Event Detail",
          key: "eventDetail",
        },
        {
          label: "Status",
          key: "status",
        },
        {
          label: "Action",
          key: "action",
        },
      ],
      showMediaPreview: false,
      actionOptions: [
        {
          text: "Show",
          value: 1,
        },
        {
          text: "Hide",
          value: 0,
        },
        {
          text: "Remove",
          value: 3,
        },
        {
          text: "Edit",
          value: 4,
        },
      ],
    };
  },
  async mounted() {
    let response = await this.$store.dispatch(
      "candidates/getCandidate",
      this.$route.params.id
    );
  },
  computed: {
    eventItems() {
      return this.candidateModel.events.map(function (item) {
        return { ...item, action: item.isVisible ? 1 : 0 };
      });
    },
  },
  methods: {
    async init() {
      this.loading = true;
      try {
        if (this.$store.state.candidates.candidate == null) {
          let events = await this.$store
            .dispatch("candidates/getCandidate", this.$route.params.id)
            .then((res) => res.events);
          this.items = events.map(function (item) {
            return { ...item, action: item.isVisible == 1 ? 1 : 2 };
          });
        }
      } catch (error) {
        this.loading = false;
        this.errorMessage = error;
      }
    },
    onOptionChange(e, args) {
      if (args.action === 4) {
        this.$router.push({ name: "edit-event", params: { eventId: args.id } });
      }
    },
    onPreview() {},
    onBack() {
      this.$router.push("add-event");
    },
    onSkip() {
      this.$router.push("add-endorsement");
    },
    onSave() {
      this.$refs.submit.click();
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.$route.params.id,
        items: this.eventItems.map(function (item) {
          return { id: item.id, mediaAction: item.action };
        }),
      };

      this.loading = true;
      this.saving = true;

      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.candidateService
            .SaveEventTableAsync(payload)
            .then((res) => {
              this.$store.commit("candidates/SET_CANDIDATE", res.data);
              setTimeout(() => {
                this.saving = false;
                if (fromBreadcrumb != true) {
                  this.$router.push("add-endorsement");
                }
              }, 500);
            })
            .catch((e) => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-wizard {
  .table {
    .event-detail {
      max-width: 350px;
      line-height: 20px;

      label {
        margin-bottom: 0;
      }

      li {
        margin-bottom: 10px;
      }
    }

    .custom-select {
      font-size: 14px;
    }
  }
}
</style>
